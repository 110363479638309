.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-image {
  float: left;
  position: fixed;
  width: 100%;
  z-index: -100;
  top: 0;
  left: 0;
}

.bg-gradient-white {
  background: linear-gradient(180deg, #FFFFFF 27.06%, rgba(196, 196, 196, 0) 100%);
  float: left;
  height: 170px;
  position: fixed;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

#menu-modal .modal-content {
  min-height: 350px;
}

div .rounded-circle {
  width: 60px;
  height: 60px;
}

.btn-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 90px;
  right: 20px;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}